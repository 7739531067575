import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import image from "../images/link-preview.png";
import { useCookies } from "react-cookie";
function Seo({ description, lang, meta, title, slug, locale, noindexForce, isBlogDetail = false }) {
  const [_, setCookie] = useCookies([
    "cookie_consent_user_accepted",
  ]);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  );
  const keywords = site.siteMetadata.keywords;
  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  setCookie("cookie_consent_user_accepted", false,{ path: '/' });

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title ? title : defaultTitle}
      link={isBlogDetail && locale !== "sv" && process.env.GATSBY_ENV === "production" ? [
        {
          rel: "alternate",
          hrefLang: "x-default",
          href: `https://opsiocloud.com/${slug}/`
        },
        {
          rel: "alternate",
          hrefLang: "en",
          href: `https://opsiocloud.com/${slug}/`
        },
        {
          rel: "alternate",
          hrefLang: "en-in",
          href: `https://opsio.in/${slug}/`
        },
      ] : []}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:image`,
          content: image,
        },

        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: !slug
            ? `${site.siteMetadata?.siteUrl}/`
            : `${site.siteMetadata?.siteUrl}/${
                slug?.startsWith("/") ? slug.slice(1) : slug
              }${slug === "/" ? "" : "/"}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content:
            noindexForce || process.env.GATSBY_ENV === "staging"
              ? "noindex nofollow"
              : "index follow",
        },
        {
          name: `ahrefs-site-verification`,
          content:
            "62b049ad52a20321e5077e5a343904cc5d2e60d6413475990e5372d5000629f3",
        },
      ].concat(meta)}
    >
      {locale === "en" && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-219966612-1"
          type="text/plain"
          cookie-consent="tracking"
        />
      )}

      {locale === "en" && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10865368368"
          type="text/plain"
          cookie-consent="tracking"
        />
      )}

      {locale === "sv" && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-PP1G19T52Y"
          type="text/plain"
          cookie-consent="tracking"
        />
      )}

      {locale === "en-IN" && (
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-NFV34T1P45"
          type="text/plain"
          cookie-consent="tracking"
        />
      )}
    </Helmet>
  );
}
export default Seo;

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
